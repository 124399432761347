import Image from "next/image";
import Link from "next/link";
import OrbitingCircles from "@/src/components/magicui/orbiting-circles";

export const CTARow = () => {
  return (
    <section className="mx-auto flex w-full flex-col items-center bg-[#FFFFFF] pt-24">
      <div className="inline-flex flex-col items-center space-y-24">
        <Image draggable={false} alt="img" width={1520} height={353} src="/ctabanner.png" />
      </div>
      <div className="relative flex h-[400px] w-full flex-col items-center justify-center overflow-hidden rounded-lg border bg-lightGray md:shadow-xl">
        <Image
          src="/sellRazeWhiteMetallic.png"
          alt="SellRaze Logo"
          width={100}
          height={100}
          className="opacity-60 absolute z-0 drop-shadow-[0_20px_20px_rgba(0,0,0,0.25)]"

        />

        <div className="absolute z-10 flex w-full h-full flex-col items-center justify-center space-y-10 px-5">
          <div className="space-y-4 text-center">
            <div className="sm:text-7xl sm:leading-[72px] w-full text-wrap font-sans text-4xl font-bold text-[#111111] lg:text-7xl">
              The future of selling online is here.
            </div>
            <div className="sm:max-w-full sm:leading-[72px] text-wrap font-sans text-2xl font-[600] text-[#111111]">
              List fast, and earn fast with SellRaze.
            </div>
          </div>
          <Link
            href='https://sellraze.com/dashboard'
            className="my-2 mr-2 animate-shimmer items-center justify-center rounded-[18px] border-2 border-blue-300 bg-[linear-gradient(110deg,#076AFF,45%,#48BDFF,55%,#076AFF)] bg-[length:200%_100%] px-6 py-4 font-sfpro font-semibold text-white text-[18px] shadow-[0_4px_15px_rgba(0,0,0,0.20)]"
            data-variant="gradient"
            data-size="thin"
          >
            Try SellRaze now
          </Link>
        </div>
        
        <OrbitingCircles
          className="size-[30px] border-none bg-transparent"
          duration={20}
          delay={20}
          radius={80}
        >
          {[
            <Image key="mercari" src="/circlesMercari.png" alt="Mercari" width={30} height={30} />,
            <Image key="amazon" src="/circlesAmazon.png" alt="Amazon" width={30} height={30} />,
            <Image key="facebook" src="/circlesFacebook.png" alt="Facebook" width={30} height={30} />,
            <Image key="depop" src="/circlesDepop.png" alt="Depop" width={30} height={30} />,
            <Image key="offerup" src="/circlesOfferup.png" alt="Offerup" width={30} height={30} />,
          ]}
        </OrbitingCircles>
        <OrbitingCircles
          className="size-[50px] border-none bg-transparent"
          radius={190}
          duration={20}
          reverse
        >
          {[
            <Image key="facebook" src="/circlesFacebook.png" alt="Facebook" width={50} height={50} />,
            <Image key="depop" src="/circlesDepop.png" alt="Depop" width={50} height={50} />,
            <Image key="offerup" src="/circlesOfferup.png" alt="Offerup" width={50} height={50} />,
            <Image key="ebay" src="/circlesPoshmark.png" alt="Ebay" width={50} height={50} />,
            <Image key="mercari" src="/circlesStockX.png" alt="Mercari" width={50} height={50} />,
          ]}
        </OrbitingCircles>

        <OrbitingCircles
          className="size-[50px] border-none bg-transparent"
          radius={190}
          duration={20}
          delay={20}
          reverse
        >
          <Image src="/circlesDepop.png" alt="Depop" width={50} height={50} />
        </OrbitingCircles>
        <OrbitingCircles
          className="size-[50px] border-none bg-transparent"
          radius={190}
          duration={20}
          delay={40}
          reverse
        >
          <Image src="/circlesEbay.png" alt="Ebay" width={50} height={50} />
        </OrbitingCircles>
      </div>
    </section>
  );
};